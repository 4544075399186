<template>
  <div class="orders">
    <div class="order-table">
      <div class="order-table-thead">
        <b-icon class="order-icon" icon="chevron-left"></b-icon>
        <span style="margin: 0 20px">2022 年 01 月</span>
        <b-icon class="order-icon" icon="chevron-right"></b-icon>
      </div>
      <div class="order-table-tr">
        <div class="week" v-for="item in weeks" :key="item">
          {{ item }}
        </div>
      </div>
      <div class="order-table-tr" style="flex-wrap: wrap">
        <div
          class="day"
          v-for="(day, i) in days"
          :key="i"
          @click="selectDay(day)"
          :class="dayActive == day.day ? 'active' : ''"
        >
          <div class="day-text">
            <span
              style="font-weight: bold"
              v-if="
                String(check.year + check.month + day.day) ==
                String(now.year + now.month + now.day)
              "
              >今</span
            >
            <span v-else>{{ day.day }}</span>
          </div>
          <div
            :class="dayActive == day.day ? 'day-active' : 'day-status'"
            v-if="day.status == 'false'"
          >
            可预约
          </div>
        </div>
      </div>
    </div>
    <div class="order-timestr">
      <div class="order-list">
        <div
          class="item"
          v-for="time in selectTimes"
          :key="time"
          :class="timeActive == time ? 'time-active' : ''"
          @click="selectTime(time)"
        >
          {{ time }}
        </div>
      </div>
      <div class="order-submit">
          <b-button class="order-btn" variant="danger" pill @click="submit">提交</b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      weeks: ["日", "一", "二", "三", "四", "五", "六"],
      check: {
        year: "",
        month: "",
      },
      now: {
        year: "",
        month: "",
      },
      days: [],
      selectTimes: [
        "9:00",
        "9:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
      ],
      dayActive: "",
      timeActive: "",
    };
  },
  props: ["doctorId", "hospitalId"],
  mounted() {
    let date = new Date();
    this.now.year = date.getFullYear();
    this.now.month = date.getMonth() + 1;
    this.now.day = date.getDate();
    console.log(this.now);
    this.check = this.now;
    if (this.check.month < 10) {
      this.check.month = "0" + this.check.month;
    }
  },
  methods: {
    getOrders() {
      let data = {
        doctors_id: this.doctorId,
        hospital_id: this.hospitalId,
        datetime: this.getTimestr(),
      };
      this.$http.appointmentlist(data).then((res) => {
        console.log(res);
        let { data, code, msg } = res;
        if (code == 1) {
          let span = new Date().getDay();

          let arr = [];
          for (let i in data) {

            arr.push({
              day: i,
              status: data[i]?data[i]:'false',
            });
          }
          for (let i = 0; i < span; i++) {
            arr.unshift({});
          }
          this.days = arr;
          // console.log(arr)
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getTimestr() {
      // console.log(this.check);
      let time = this.check.year + "/" + this.check.month + "/" + "01 00:00:01";
      let tr = new Date(time).getTime();
      return String(tr / 1000);
    },
    selectDay(day) {
      if (day.status != "false") {
        return;
      } else {
        this.dayActive = day.day;
      }
    },
    selectTime(time) {
      if (!this.dayActive || this.dayActive == "") {
        this.$store.commit("showToast", "请选择日期");
        return;
      }
      this.timeActive = time;
    },
    confirm() {},
    submit() {
        let nowtime = new Date().getTime()
        let time = this.check.year+'/'+this.check.month+'/'+this.dayActive+" "+this.timeActive+":00"
        let timestr = new Date(time).getTime()
        if(timestr < nowtime) {
            this.$store.commit("showToast", "预约时间必须大于当前时间")
            return;
        } 
        this.$emit("selectDate", timestr/1000)   
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./order.scss";
</style>
