<template>
  <div class="page">
    <div class="content">
      <div class="step">
        <b-progress height="1.5rem" :value="Number(step * 25)" :max="max">
          <b-progress-bar :value="Number(step * 20)" style="background: #fd9852">
          </b-progress-bar>
        </b-progress>
        <div class="progress-div">
          <div
            class="progress-item"
            :class="step == 0 ? 'active' : ''"
            @click="changeStep(0)"
          >
            1. 选择医生
          </div>
          <div
            class="progress-item"
            :class="step == 1 ? 'active' : ''"
            @click="changeStep(1)"
          >
            2. 选择医院
          </div>
          <div
            class="progress-item"
            :class="step == 2 ? 'active' : ''"
            @click="changeStep(2)"
          >
            3. 选择时间
          </div>
          <div
            class="progress-item"
            :class="step == 3 ? 'active' : ''"
            @click="changeStep(3)"
          >
            4. 确认订单
          </div>
          <div class="progress-item" :class="step == 4 ? 'active' : ''">5. 完成订单</div>
        </div>
      </div>

      <div class="forms">
        <div v-show="step == 0">
          <Doctors ref="doctors" @selectDoctor="selectDoctor"></Doctors>
        </div>
        <div v-show="step == 1">
          <Hospital
            ref="hospital"
            :doctor="doctor.id"
            @selectHospital="selectHospital"
          ></Hospital>
        </div>
        <div v-show="step == 2">
          <order-table
            ref="table"
            :doctorId="doctor.id"
            :hospitalId="hospital.id"
            @selectDate="selectDate"
          ></order-table>
        </div>
        <div v-show="step == 3">
          <div class="order-form">
            <div class="left-form">
              <div class="form-title" style="font-size: 20px; text-align: center">
                <strong>订单信息</strong>
              </div>
              <div class="form-title"><strong>就诊人：</strong></div>
              <div>
                {{ user.nickname }}
                <span style="margin-left: 30px">{{ user.mobile }}</span>
              </div>
              <div class="form-title"><strong>商品信息：</strong></div>
              <div class="form-goods">
                <div style="width: 100px; margin-right: 20px">
                  <img
                    :src="$host + info.image_default_id"
                    style="width: 100px; height: 100px"
                  />
                </div>
                <div>
                  <div style="height: 76px">
                    {{ info.name }}
                  </div>
                  <div style="height: 24px">
                    ￥{{ info.product ? info.product.price : 0 }}
                    <span style="margin: 0 20px">X</span> {{ $route.query.num }}件
                  </div>
                </div>
              </div>
              <div class="tr">预约医生： {{ doctor.nickname }}</div>
              <div class="tr">预约医院： {{ hospital.title }}</div>
              <div class="tr">
                预约时间： {{ $timeFormat(timestr, "yyyy-mm-dd h:MM") }}
              </div>
              <div class="tr">
                会员折扣：
                <span style="color: red"
                  >-
                  {{
                    result.user_discount_amount ? result.user_discount_amount : 0
                  }}</span
                >
              </div>
              <div class="tr">备注：</div>
              <div>
                <b-textarea placeholder="备注" v-model="momo"></b-textarea>
              </div>
              <div style="margin: 10px 0">
                支付方式：
                <!-- <b-form-select v-model="pay_type" style="width: 200px">
                  <b-form-select-option value="alipay_web">支付宝</b-form-select-option>
                  <b-form-select-option value="wechat_scan">微信</b-form-select-option>
                </b-form-select> -->
                <div style="display: flex">
                  <div class="pay-item" :class="pay_type == 'alipay_web'?'pay-active':''" @click="pay_type = 'alipay_web'"><img class="img" src="../../static/shop/zfbicon.png"/> 支付宝</div>
                  <div class="pay-item" :class="pay_type == 'wechat_scan'?'pay-active':''" @click="pay_type = 'wechat_scan'"><img class="img" src="../../static/shop/wxicon.png"/> 微信</div>
                </div>
              </div>
              <div style="margin: 20px 0">
                <b-button variant="primary" @click="submit">提交订单</b-button>
              </div>
            </div>

            <div class="right-form" v-if="order_bn != ''">
              <div v-show="pay_type == 'wechat_scan'">
                <div class="form-title" style="font-size: 20px; text-align: center">
                  <strong>微信支付</strong>
                </div>
                <div
                  style="text-align: center; color: #999; font-size: 14px; margin: 10px 0"
                >
                  微信扫一扫支付
                </div>
                <div
                  class="qrcode-area"
                  ref="qrcode"
                  style="display: flex; justify-content: center; margin: 10px 0"
                ></div>
                <div style="text-align: center; margin: 30px 0">
                  <b-button variant="primary" @click="endPay">完成</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="step == 4">
          <div
            style="
              width: 1200px;
              display: flex;
              padding: 20px 0;
              justify-content: center;
              height: 500px;
              align-items: center;
            "
          >
            <div style="width: 300px">
              <div style="text-align: center">支付成功</div>
              <div style="text-align: center">
                <b-link class="success-item" style="margin-right: 20px">查看订单</b-link>
                <b-link class="success-item">返回首页</b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Doctors from "./doctors.vue";
import Hospital from "./hospital.vue";
import OrderTable from "./table.vue";
import Qrcode from "qrcodejs2";
export default {
  data() {
    return {
      max: 100,
      doctor: {},
      hospital: {},
      step: 0,
      timestr: "",
      cart_md5: "",
      result: {},
      coupons: [],
      info: {},
      integral: 0,
      ratio: "",
      pay_type: "alipay_web",
      momo: "",
      code_url: "weixin://wxpay/bizpayurl?pr=kJNeBJ7zz",
      order_bn: "",
      html: "",
    };
  },
  components: {
    Doctors,
    Hospital,
    OrderTable,
  },

  computed: {
    user() {
      let user = JSON.parse(this.$cache.getCache("userInfo"));
      return user;
    },
  },
  mounted() {
    this.getGoodsInfo();
  },
  methods: {
    selectDoctor(option) {
      this.doctor = option;
      this.step = 1;
      this.$refs.hospital.getHospital(option.id);
      this.getCheckout();
    },
    selectHospital(option) {
      this.hospital = option;
      this.step = 2;
      this.$refs.table.getOrders();
    },
    selectDate(option) {
      this.timestr = option;
      this.step = 3;
    },
    getCheckout() {
      this.$http
        .confrim_buy({
          is_delivery: "N",
          doctors_id: this.doctor.id,
        })
        .then((res) => {
          console.log(res);
          let { data, code, msg } = res;
          if (code == 1) {
            this.result = data.total;
            this.cart_md5 = data.cart_md5;
            this.coupons = data.my_av_coupons;
            this.ratio = data.exchange_ratio;
            this.integral = data.user_integral;
          } else {
            this.$store.commit("showToast", msg);
          }
        });
    },
    getGoodsInfo() {
      this.$http
        .shop_goodsdetail({
          id: this.$route.query.id,
        })
        .then((res) => {
          let { data, code, msg } = res;
          if (code == 1) {
            this.info = data;
          } else {
            this.$store.commit("showToast", msg);
          }
        });
    },
    submit() {
      if (!this.doctor.id || !this.hospital.id || this.timestr == "") {
        return this.$store.commit("showToast", "请选择医生、医院、预约时间");
      }
      if (this.pay_type == "") {
        return this.$store.commit("showToast", "请选择支付方式");
      }

      let data = {
        memo: this.momo,
        fastbuy: true,
        need_shipping: "N",
        pay_id: this.pay_type,
        hospital_id: this.hospital.id,
        doctors_id: this.doctor.id,
        reservetime: this.timestr,
        cart_md5: this.cart_md5,
        cost_integral: 0,
      };
      this.$http.createorder(data).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let obj = {
            order_bn: res.data.order_bn,
            pay_app: this.pay_type,
          };
          this.order_bn = obj.order_bn;
          this.toPay(obj);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
    toPay(obj) {
      this.$http.dopayment(obj).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$store.commit("showToast", "订单确认成功，正在发起支付...");
          if (this.pay_type == "wechat_scan") {
            this.code_url = res.data.code_url;
            this.createQrcode(res.data.code_url);
          }
          if (this.pay_type == "alipay_web") {
            let html = res.data;
            let div = document.createElement("divform");
            div.innerHTML = html;
            document.body.appendChild(div);
            document.forms[0].acceptCharset = "GBK"; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
            document.forms[0].submit();
          }
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
    createQrcode(url) {
      let qrcode = new Qrcode(this.$refs.qrcode, {
        width: 200, //图像宽度
        height: 200, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: Qrcode.CorrectLevel.H, //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.clear();
      qrcode.makeCode(url);
    },
    changeStep(index) {
      console.log(this.doctor.id);
      switch (index) {
        case 0:
          this.step = 0;
          break;
        case 1:
          if (this.doctor.id) {
            this.step = 1;
          } else {
            this.$store.commit("showToast", "请选择医生");
          }
          break;
        case 2:
          if (this.doctor.id && this.hospital.id) {
            this.step = 2;
          } else {
            this.$store.commit("showToast", "请选择医生及医院");
          }
          break;
        case 3:
          this.step = 3;
          break;
      }
    },
    endPay() {
      let data = {
        order_bn: this.order_bn,
      };
      this.$http.order_detail(data).then((res) => {
        if (res.code == 1) {
          this.$store.commit("showToast", "订单支付成功...");
          setTimeout(() => {
            this.step = 4;
          }, 1500);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./order.scss";
.order-form {
  margin: 20px 0;
  display: flex;
  .left-form {
    width: 600px;
    border: 1px solid #ccc;
    padding: 10px 20px;
  }
  .right-form {
    width: 600px;
    padding: 0 50px;
  }
  .form-title {
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    margin-top: 5px;
  }
  .form-goods {
    display: flex;
    margin: 10px 0;
  }
  .tr {
    height: 32px;
    line-height: 32px;
  }
}
.success-item {
  text-decoration: none;
  height: 32px;
  line-height: 32px;
}
.pay-item {
  margin-top: 10px;
  width: 100px;
  height: 40px;
  border: 2px solid #ccc;
  line-height: 40px;
  margin-right: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  .img {
    width: 20px;
    height: auto;
    margin-right: 5px;
  }
}
.pay-item:hover {
  cursor: pointer;
}
.pay-active {
  border: 2px solid #0161fd;
  color: #0161fd;
}
</style>
