<template>
  <div class="doctors">
    <div class="item" v-for="(item, index) in doctors" :key="index">
      <img class="item-avatar" :src="item.src" @error="defaultAvatar(index)" />
      <div class="item-name">
        {{ item.nickname }}
        <span class="item-more">{{ item.position ? item.position.name : "" }}</span>
      </div>
      <div class="item-rate">
        好评率：{{ item.favorable_rate }}%
        <span class="item-more">{{ item.user_order }}人已就诊</span>
      </div>
      <div class="item-select">
        <b-button variant="outline-primary" pill @click="select(item)">立即选择</b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      doctors: [],
      page: 1,
      limit: 4,
    };
  },
  mounted() {
      this.getDoctors()
  },
  methods: {
    getDoctors() {
      let data = {
        goods_id: this.$route.query.goods_id,
        page: this.page,
        limit: this.limit,
      };
      this.$http.goods_doctors(data).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let { items } = res.data;
          items.forEach((i) => {
            i.src = this.$host + i.avatar;
          });
          this.doctors = items;
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
    defaultAvatar(i) {
      this.doctors[i].src = require("../../static/my/empty-image-error.png");
    },
    select(opt) {
        this.$emit("selectDoctor", opt)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./order.scss";
</style>

