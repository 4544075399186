<template>
  <div class="doctors">
    <div class="item" v-for="(item, index) in hospitals" :key="index">
      <img class="item-avatar" :src="item.src" @error="defaultIMG(index)" />
      <div class="item-name">{{ item.title }}</div>
      <div class="item-address">{{ item.address }}</div>
      <div class="item-select">
        <b-button variant="outline-primary" pill @click="select(item)">立即选择</b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 4,
      hospitals: [],
    };
  },
  props: ["doctor"],
  methods: {
    getHospital(id) {
      let data = {
        page: this.page,
        limit: this.limit,
        doctors_id: id,
      };
      this.$http.hospitallist(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          data.items.forEach((item) => {
            item.src = this.$host + item.logo;
          });
          this.hospitals = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    defaultIMG(i) {
      this.hospitals[i].src = require("../../static/doctor/hospital.jpg");
    },
    select(item) {
      this.$emit("selectHospital", item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./order.scss";
</style>
